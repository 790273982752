import React from "react";
import emailjs from 'emailjs-com';
import "../Contact/Contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Contact() {

    function sendEmail(e) {
      e.preventDefault();
  
      emailjs.sendForm('service_nu5vxho', 'gmail_template', e.target, 'user_ZVuuqMAdHGN8lM2fsoftG')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    }
  
    return (
      <form className="contact-form" onSubmit={sendEmail} autocomplete="off">
        <div className="name-input">
        <label>Name:</label>
        <input type="text" name="name" />
        </div>
        <div className="email-input">
        <label>Email:</label>
        <input type="email" name="email" />
        </div>
        <div className="message-input">
        <label>Message:</label>
        <textarea name="message" />
        </div>
        <div className="icons-and-buttons">
        <input className="submit-button" type="submit"/>
        <div className="icons-container">
         <a href="https://www.linkedin.com/in/rayesh-rajah-ba7902190/"><FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" color="white"/></a>
         <a href="https://www.github.com/rayeshrajah"><FontAwesomeIcon icon={['fab', 'github-square']} size="2x" color="white" /></a>
        </div>
        </div>
      </form>
    );
  }