import React from "react";
import "../Projects/Projects.css";
import PokemonSage from "../../docs/pokesage.gbc";

export default function Projects() {
    return (
        <div className="projects-main-container">
             <div className="project-info-one">
                <a href="https://www.github.com/rayeshrajah/spoiler_boiler">Spoilerboiler:</a>
                <p>Spoiler boiler is a goofy app where you can
                comment and place unique tags at specific video
                timestamp. A graph representing the statistics of the comments and tags for each video generated. 
                Created our own api to fetch videos available on youtube as a url.
                </p>
                <p>Tech Stack: React.js, Ruby on Rails, Node.js, PostgreSQL</p>
             </div>
             <div className="project-info-two">
                <a href="https://www.github.com/rayeshrajah/quebec_nature">Quebec Nature:</a>
                <p>Quebec Nature is an application where you can explore different national parks that are within Quebec. Check out this project to discover the hidden gems of this province.
                </p>
                <p>Tech Stack: React.js, Css</p>
             </div>
             <div className="project-info-three">
                <a href={PokemonSage} download="pokesage.gbc">Pokemon Sage:</a>
                <p>A collaborations between a couple of friends who are also fans of Pokemon. We are developing a story-rich pokemon generation 2 game. We felt like the older versions of the game lacked story and design. 
                </p>
                <p>Tech Stack: Assembly, Node.js, Python, Git, Slack, Kanban</p>
             </div>
        </div>
    );
}