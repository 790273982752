import React from 'react';
import "../Work/Work.css";

export default function Work() {
    return (
        <div className="work-main-container">
            <div className="work-info-container">
                <h4>Work: </h4>
                <div className="work-info-one">
                    <h5>Front-end Developer @ClassRT</h5>
                    <p>ClassRT is a online learning platform where anyone can learn and teach. Used React, Redux, Sass to make the functionality of the web application. 
                       Created a google calendar like scheduling system to view course schedule. Tested React componenets with Jest and Enzyme. 
                    </p>
                </div>
            </div>
            <div className="education-info-container">
                <h4>Education: </h4>
                <div className="education-info-one">
                    <h5>Diploma in Web Development @LightHouse Labs</h5>
                    <p>12-week intensive bootcamp where I have gained extensive knowledge and experience in JavaScript, React, JQuery, Ruby on Rails, ExpressJS, PostgreSQL, HTML, CSS and many more...Created multiple project using these languages. 
                    </p>
                </div>
                <div className="education-info-two">
                    <h5>Studies in Computer Science @Concordia University</h5>
                    <p>I was enrolled in Concordia University's Computer Science program, where I learned Data Structures and Algorithms, Operating Systems and Databases.  
                    </p>
                </div>
                <div className="education-info-three">
                    <h5>Diploma of College Studies @Vanier College</h5>
                    <p>Studies in Computer Science followed by Mathematics and Social Sciences. In the Computer Science program I learned about Object-Oriented Programming with Java, Html and CSS to build basic website, linux terminal commmand line to manipulate files. 
                    </p>
                </div>
            </div>
        </div>
    );
}