import React from "react";
import ProfileImage from "../../Images/profile-image.jpg";
import "../About/About.css";

export default function About() {
    return (
        <div className="about-main-container">
            <img src={ProfileImage} alt="profile-avatar" />
            <p> Hello my name is Rayesh, you can call me Ray for short. I love playing
            basketball, video games and programming. Ever Since I was young I always had a passion for 
            video games. As years went by, games just became aesthetically pleasing, but I wasn't sure how 
            the functionality of games worked and thats where I got really interested in coding. I love learning 
            new technologies and I try to implement them when I work on personal projects. I have posted some
            of the projects that are completed or I am working on feel free to check them out. 
            </p>
        </div>
    );
}