import "./App.css";
import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Work from "./components/Work/Work";
import Contact from "./components/Contact/Contact";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faGithubSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import CircleLoader from "react-spinners/CircleLoader";

library.add(fab, faGithubSquare, faLinkedin);

function App() {
//   const [state, setState] = useState({
//     about: false,
//     projects: false,
//     work: false,
//     contact: false,
//   });

  const [about, setAbout] = useState(false);
  const [projects, setProjects] = useState(false);
  const [work, setWork] = useState(false);
  const [contact, setContact] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleBackClick = (e, setState) => {
    e.stopPropagation()
    setState(false);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [loading]);

  return (
    <div className="App">
      {loading ? (
        <CircleLoader size={300} color={"#FFFFFF"} loading={loading} />
      ) : (
        <div className="app-container-two">
          {/* <Navbar setState={setState} state={state} /> */}
          <div className="app-four-quadrant-container">
            <div className={`app-quadrant-about ${about ? '--active' : ''} ${projects || work || contact ? '--inactive' : ''}`} onClick={() => setAbout(true)}>
              {about && 
                <div className="back-to-main"  onClick={(e) => handleBackClick(e, setAbout)}>
                  Back
                </div>
              }
              <h1>About me</h1>
              {about && <About />}
            </div>
            <div className={`app-quadrant-projects ${projects ? '--active': ''} ${about || work || contact ? '--inactive' : ''}`} onClick={() => setProjects(true)}>
                {projects && 
                  <div className="back-to-main"  onClick={(e) => handleBackClick(e, setProjects)}>
                    Back
                  </div>
                }
                <h1>Projects</h1>
                {projects && <Projects />}
            </div>
            <div className={`app-quadrant-work ${work ? '--active' : ''} ${about || projects || contact ? '--inactive' : ''}`} onClick={() => setWork(true)}>
                {work && 
                  <div className="back-to-main"  onClick={(e) => handleBackClick(e, setWork)}>
                    Back
                  </div>
                }
                <h1>Experience</h1>
                {work && <Work />}
            </div>
            <div className={`app-quadrant-contact ${contact ? '--active' : ''} ${about || projects || work ? '--inactive' : ''}`} onClick={() => setContact(true)}>
                {contact && 
                  <div className="back-to-main"  onClick={(e) => handleBackClick(e, setContact)}>
                    Back
                  </div>
                }
                <h1>Contact</h1>
                {contact && <Contact />}
            </div>
          </div>
          {/* <div className="content-container">
            {state.about && <About />}
            {state.projects && <Projects />}
            {state.work && <Work />}
            {state.contact && <Contact />}
          </div> */}
        </div>
      )}
    </div>
  );
}

export default App;
